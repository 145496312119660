var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.patologias,"search":_vm.search,"items-per-page":5,"show-select":"","item-key":"id","height":"350"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Patologías")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","fab":"","color":"primary"},on:{"click":function($event){_vm.syncedDialog=false}}},[_c('v-icon',[_vm._v("close")])],1)],1)]},proxy:true},{key:"item.puede_masticar",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.puede_masticar? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.puede_masticar? 'Sí' : 'No'))])]}},{key:"item.macro_hc",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.macro_hc? item.macro_hc : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#EA9F77"},model:{value:(item.macro_hc),callback:function ($$v) {_vm.$set(item, "macro_hc", $$v)},expression:"item.macro_hc"}})]}},{key:"item.macro_prot",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.macro_prot? item.macro_prot : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#66CCB7"},model:{value:(item.macro_prot),callback:function ($$v) {_vm.$set(item, "macro_prot", $$v)},expression:"item.macro_prot"}})]}},{key:"item.macro_gra",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.macro_gra? item.macro_gra : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#DB4965"},model:{value:(item.macro_gra),callback:function ($$v) {_vm.$set(item, "macro_gra", $$v)},expression:"item.macro_gra"}})]}},{key:"footer",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.Cancelar()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.Aceptar()}}},[_vm._v("Aceptar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }